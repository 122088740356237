<template>
	<section id="home">
        <img class="logo" src="/logo_new.png" alt="Chop">
        <div class="content">
            <div class="location">
                <img src="/icon-location.png" alt="">
                <h3>Coconut Grove</h3>
            </div>
            <h1>AN UNFORGETTABLE STEAK<br/> EXPERIENCE COMING SOON</h1>
            <h2>Subcribe to<br>Our Newsletter</h2>
            <p>Subscribe for updates on your<br> new favorite steakhouse.</p>
            <div class="form-wrapper" ref="form-wrapper"></div>
            <p class="recaptcha">
                This site is protected by reCAPTCHA and the Google<br><a href="https://policies.google.com/privacy?hl=en-US" target="_blank">Privacy Policy</a> and <a href="https://policies.google.com/terms?hl=en-US" target="_blank">Terms of Service</a> apply.
            </p>
        </div>
    </section>
</template>

<script>

export default {
	name: 'App',
    mounted() {
        const form = document.querySelector('form#_form_37_');
        this.$refs['form-wrapper'].appendChild(form);
    }
};

</script>

<style>

@font-face {
    font-family: 'Apercu Pro';
    src: url('~@/assets/fonts/ApercuPro-Regular.woff2') format('woff2'),
        url('~@/assets/fonts/ApercuPro-Regular.woff') format('woff');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu Pro';
    src: url('~@/assets/fonts/ApercuPro-Light.woff2') format('woff2'),
        url('~@/assets/fonts/ApercuPro-Light.woff') format('woff');
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'Apercu Pro';
    src: url('~@/assets/fonts/ApercuPro-Bold.woff2') format('woff2'),
        url('~@/assets/fonts/ApercuPro-Bold.woff') format('woff');
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'Domaine Display';
    src: url('~@/assets/fonts/DomaineDisplay-Regular.woff2') format('woff2'),
        url('~@/assets/fonts/DomaineDisplay-Regular.woff') format('woff');
    font-weight: 400;
    font-display: swap;
}

html, body, #app, #home {
    
    height: 100%;
    margin: 0;

    overflow: hidden;

}

body {
    font-family: 'Apercu Pro', sans-serif;
    color: #fff;
}

h1, h2, h3, p {
    margin: 0;
}

* {
    box-sizing: border-box;
}

#home {
    
    position: relative;

    background-image: url('~@/assets/images/bg.jpg');
    background-size: cover;
    background-position: center center;

    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;

}

#home:after {

    content: '';
    
    position: fixed;
    inset: 0;
    
    background-color: rgba(0, 0, 0, 0.7);

}

img.logo {

    position: absolute;
    top: 40px;
    left: 30px;
    z-index: 10;

    height: 35px;

}

.content {
    position: relative;
    z-index: 10;
}

.location img {
    height: 20px;
}

.location h3 {

    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1.7;

}

h1, h2 {
    font-family: 'Domaine Display', serif;
    font-weight: 400;
}

h1 {
    
    font-size: 48px;
    letter-spacing: 4px;
    text-transform: uppercase;

    margin-top: 2vh;
    padding: 0 20px;

}

h2 {
    
    font-size: 32px;
    letter-spacing: 7px;
    text-transform: uppercase;
    line-height: 1.25;

    margin-top: 6vh;

}

p {
    
    font-weight: 300;
    font-size: 16px;
    line-height: 1.2;

    margin-top: 10px;

}

p br {
    display: none;
}

.form-wrapper {
    margin-top: 40px;
}

.form-wrapper .row {
    
    display: flex;
    gap: 20px;
    justify-content: center;

    margin-bottom: 20px;

}

.form-wrapper input,
.form-wrapper button,
.form-wrapper select {

    appearance: none;
    outline: none;

    font-family: 'Apercu Pro', sans-serif;
    font-weight: 400;
    font-size: 15px;

}

.form-wrapper input {

    background: #fff;

    width: 250px;
    height: 54px;
    padding: 0 15px;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    
    color: #000;

}

.form-wrapper input::placeholder {
    color: rgba(0, 0, 0, 0.3);
}

.form-wrapper button {

    background: none;

    width: 160px;
    height: 60px;
    margin-top: 20px;
    border: 1px solid #fff;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 16px;

    color: #fff;
    text-transform: uppercase;

    transition: color 250ms ease, background-color 250ms ease;

    cursor: pointer;

}

.form-wrapper button:hover {
    background-color: #fff;
    color: #000;
}

.form-wrapper label {

    display: block;
    
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    text-align: left;
    letter-spacing: 1px;

    width: 250px;
    padding-bottom: 7px;
    border-bottom: 1px solid #fff;

}

.form-wrapper select {

    background: rgba(0, 0, 0, 0);;
    appearance: none;

    width: 250px;
    height: 34px;
    padding: 0;
    border: none;

    color: #fff;

}

.form-wrapper select * {
    background: rgba(0, 0, 0, 0.3);
    color: #000;
}

/* Wrapper element for Birth Month. */
.form-wrapper ._x96613172 {
    position: relative;
}

/* Caret. */
.form-wrapper ._x96613172:after {
    
    content: '';

    position: absolute;
    bottom: 14px;
    right: 3px;

    width: 7px;
    height: 7px;

    border: 1px solid #fff;
    border-top: none;
    border-left: none;

    transform: rotate(45deg);

    pointer-events: none;

}

.form-wrapper ._error {
    
    padding-top: 10px;

    font-size: 14px;
    text-align: left;
    color: #f00;

}

.form-wrapper ._form_error {
    font-size: 14px;
    color: #f00;
}

.form-wrapper ._form-thank-you {
    font-size: 20px;
}

p.recaptcha {
    
    font-size: 12px;
    line-height: 1.4;
    color: #b1b1b1;

    margin-top: 20px;

}

p.recaptcha a {
    color: inherit;
    transition: color 250ms ease;
}

p.recaptcha a:hover {
    color: #fff;
}

.grecaptcha-badge { 
    visibility: hidden !important;
}

@media (max-width: 800px) {
    h1 {
        font-size: 32px;
    }
}

@media (max-width: 600px) {

    #home {
        
        align-items: flex-start;
        
        padding: 80px 0;

        overflow-y: auto;

    }

    img.logo {

        top: 30px;
        left: 20px;

        height: 25px;

    }

    h1 {
        font-size: 24px;
    }

    h1 br {
        display: none;
    }

    h2 {
    
        font-size: 22px;
        letter-spacing: 5px;

    }

    p {
        font-size: 15px;
    }

    p br {
        display: initial;
    }

    .form-wrapper .row {
    
        flex-direction: column;
        align-items: center;

    }

    ._full_width {
        width: 250px;
    }

    ._button-wrapper {
        margin: 0 auto;
    }

}

</style>
